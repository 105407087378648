
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// Libraries
import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import socialPreview from '../img/social/preview.jpg'

function Seo({ description, lang, meta, title, image, custom, event, useEventsJsonLd = false}) {
	const { site } = useStaticQuery(graphql`
    	query {
        	site {
          		siteMetadata {
            		title
            		description
            		openGraph {
            			title
            			description
            			siteName
            			url
            			type
            			locale
            			imageWidth
            			imageHeight
            		}
            		twitter {
            			title
            			description
            			creator
            			site
            			domain
            			url
            			card
            		}
          		}
        	}
      	}
    `)

	const metaDescription = description || site.siteMetadata.description,
		defaultTitle = site.siteMetadata?.title,
		openGraph = site.siteMetadata?.openGraph,
		ogImage = image ? image : openGraph?.url + socialPreview,
		twitter = site?.siteMetadata?.twitter

	const isBrowser = typeof window !== 'undefined'

	const metaTags = [
		{
			name: `format-detection`,
			content: `telephone=no`,
		},
		{
			name: `description`,
			content: metaDescription,
		},
		{
			property: `og:title`,
			content: custom ? title : openGraph?.title,
		},
		{
			property: `og:description`,
			content: openGraph?.description,
		},
		{
			property: `og:site_name`,
			content: openGraph?.siteName,
		},
		{
			property: `og:url`,
			content: isBrowser ? window.location.href : openGraph?.url,
		},
		{
			property: `og:type`,
			content: openGraph?.type,
		},
		{
			property: `og:image`,
			content: ogImage,
		},
		{
			property: `og:image:width`,
			content: openGraph?.imageWidth,
		},
		{
			property: `og:image:height`,
			content: openGraph?.imageHeight,
		},
		{
			name: `twitter:title`,
			content: twitter?.title,
		},
		{
			name: `twitter:description`,
			content: twitter?.description,
		},
		{
			property: `og:locale`,
			content: openGraph?.locale,
		},
		{
			name: `twitter:card`,
			content: openGraph?.card,
		},
		{
			name: `twitter:creator`,
			content: twitter?.creator,
		},
		{
			name: `twitter:site`,
			content: twitter?.site,
		},
		{
			property: `twitter:domain`,
			content: twitter?.domain,
		},
		{
			property: `twitter:url`,
			content: twitter?.url,
		},
		{
			property: `twitter:image:src`,
			content: `${twitter?.url}${socialPreview}`,
		},
	].concat(meta);

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={metaTags}
		>
			{useEventsJsonLd && (
				<script type="application/ld+json">
					{`
						{
							"@context": "https://schema.org",
							"@type": "Event",
							"name": "${event.artist.name} - ${event.name}",
							"startDate": "${moment(event.starts).format()}",
							"endDate": "${moment(event.ends).format()}"
							"eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
							"eventStatus": "https://schema.org/EventScheduled",
							"location": {
								"@type": "Place",
								"name": "Event Location",
								"address": {
									"@type": "PostalAddress",
									"streetAddress": "${event.venue.addresses[0].street}",
									"addressLocality": "${event.venue.addresses[0].city}",
									"postalCode": "${event.venue.addresses[0].postal_code}",
									"addressRegion": "${event.venue.addresses[0].region}",
									"addressCountry": "GB"
								}
							},
							"image": [
								${event.image}
							],
							"description": ${event.synopsis}",
							"performer": {
								"@type": "PerformingGroup",
								"name": "${event.artist.name}"
							},
							"organizer": {
								"@type": "Organization",
								"name": "Concerts for Carers"
							}
						}
					`}
				</script>
			)}
		</Helmet>
	)
}

Seo.defaultProps = {
	lang: `en-GB`,
	meta: [],
	description: ``,
}

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	image: PropTypes.string,
}

export default Seo
